<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="pb-5">
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Номер консультации
        </div>
        <base-async-select
          v-model="consultation"
          :error="!!errors.consultation"
          :fetch-function="fetchConsultations"
          loading-title="Загрузка консультаций"
          no-options-title="Консультации не найдены"
          server-paginated
          fluid
        />
        <div
          v-if="errors.consultation"
          class="mt-2 text-danger small"
        >
          {{ errors.consultation[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Целевой врач:
        </div>
        <v-select
          v-model="selectedSpecialization"
          :options="specializations"
          placeholder="Выберите специализацию"
          fluid
          label="name"
        />

        <div
          v-if="errors.selectedSpecialization"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedSpecialization[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          ФИО врача:
        </div>
        <base-async-select
          :key="selectedSpecialization ? selectedSpecialization.id : null"
          v-model="selectedDoctor"
          :fetch-function="fetchDoctors"
          placeholder="Выберите врача"
          loading-title="Загрузка докторов"
          no-options-title="Доктора не найдены"
          server-paginated
          fluid
        />
        <div
          v-if="errors.selectedDoctor"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedDoctor[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="small font-weight-bold mb-1">
          Инициатор
        </div>
        <b-select
          v-model="selectedInitiatorId"
          value-field="id"
          text-field="name"
          :options="initiators"
        />
        <div
          v-if="errors.selectedInitiatorId"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedInitiatorId[0] }}
        </div>
      </div>
      <div class="mb-3">
        <div class="small font-weight-bold mb-1">
          Причина переноса консультации
        </div>
        <v-select
          v-model="selectedTransferReason"
          label="reason"
          :options="reasons"
          :clearable="true"
          class="crm-select"
          placeholder="Выберите причину"
          :disabled="disabledTransferReason"
          loading-title="Загрузка"
        />

        <div
          v-if="errors.selectedTransferReason"
          class="mt-2 text-danger small"
        >
          {{ errors.selectedTransferReason[0] }}
        </div>
      </div>

      <div v-if="selectedDoctor">
        <div class="schedule">
          <b-skeleton-img
            v-if="slotsLoading"
            no-aspect
            width="100%"
            height="84px"
            class="rounded"
          />
          <schedule-wrapper
            v-if="allSlots.length"
            :key="selectedDoctor.id"
            :doctor-id="selectedDoctor.id"
            :all-slots="allSlots"
          >
            <schedule @change-active-tab="activeTimeTab = $event" />
          </schedule-wrapper>

          <div v-if="!allSlots.length && !slotsLoading">
            У врача нет ни одного слота для записи. Проверьте шаблоны расписания врача
          </div>

          <div
            v-if="errors.activeTimeTab"
            class="mt-2 text-danger small"
          >
            {{ 'Необходимо выбрать слот' }}
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        @click="onClose"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="ml-2"
        @click="onClickSave"
      >
        Перенести
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';

import Bus from '@/eventBus';

import { BaseAsyncSelect } from '@/components/base';
import ScheduleWrapper from '@/components/Schedule/Modals/ScheduleWrapper';
import Schedule from '@/components/Schedule/Modals/Schedule';

import { combinedSchema } from '@/validation/zod/consultation/consultation.validation.js';

export default {
  name: 'ConsultationTransferModal',
  components: {
    BaseAsyncSelect,
    ScheduleWrapper,
    Schedule,
  },
  mixins: [validationMixin],
  props: {
    consultationProp: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Перенос консультации',
    },
    doctorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      consultation: null,
      selectedDoctor: '',
      selectedTransferReason: '',
      selectedSpecialization: '',
      activeTimeTab: '',
      specializations: [],
      reasons: [],
      selectedInitiatorId: '',
      initiators: [
        {
          id: 0,
          name: 'Врач',
        },
        {
          id: 1,
          name: 'Пациент',
        },
        {
          id: 2,
          name: 'Техническая ошибка',
        },
      ],
      allSlots: [],
      slotsLoading: false,
      isSaving: false,
      errors: {},
    };
  },
  computed: {
    disabledTransferReason() {
      return this.selectedInitiatorId === null || this.selectedDoctor === null || this.selectedSpecialization === null;
    },
    activeTab: {
      get() {
        return this.$store.state.Schedule.selectedDate;
      },
    },
  },
  watch: {
    selectedSpecialization() {
      this.selectedDoctor = null;
      this.selectedTransferReason = null;
    },
    selectedDoctor() {
      this.activeTimeTab = null;
      this.selectedTransferReason = null;
    },
    'selectedDoctor.id': {
      async handler(id) {
        await this.fetchAllSlots(id);
      },
    },
    async selectedInitiatorId() {
      await this.fetchTransferReasons();
      this.selectedTransferReason = null;
    },
  },
  async created() {
    this.consultation = this.formatConsultation(this.consultationProp);
    this.specializations = await this.fetchSpecializations('');
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async fetchAllSlots(id) {
      this.slotsLoading = true;

      try {
        this.allSlots = await this.$store.dispatch(this.$types.FETCH_DOCTOR_ALL_SLOTS, id);
      } catch (e) {
        console.error(e);
      } finally {
        this.slotsLoading = false;
      }
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        doctorSpecializationId: this.selectedSpecialization.id,
        save: false,
      });

      return doctors.map((doctor) => ({ ...doctor, value: doctor.id, name: doctor.fullName }));
    },
    async fetchTransferReasons() {
      this.reasons = await this.$store.dispatch(this.$types.TRANSFER_REASONS_FETCH, this.selectedInitiatorId);
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    async fetchConsultations({ query, skip, take }) {
      const consultations = await this.$store.dispatch(this.$types.CONSULTATIONS_FETCH, {
        skip,
        take,
        query,
      });
      return consultations.map((consultation) => this.formatConsultation(consultation));
    },
    async onClickSave() {
      this.errors = {};

      const objToValidate = {
        consultation: this.consultation,
        selectedSpecialization: this.selectedSpecialization,
        selectedDoctor: this.selectedDoctor,
        selectedInitiatorId: this.selectedInitiatorId,
        selectedTransferReason: this.selectedTransferReason,
        activeTimeTab: this.activeTimeTab,
      };

      const result = combinedSchema.safeParse(objToValidate);

      if (!result.success) {
        result.error.errors.forEach((error) => {
          let errorMsg = error.message;
          if (errorMsg === 'Invalid input') {
            if (error.path[0] === 'selectedDoctor') {
              errorMsg = 'Выберите врача';
            } else if (error.path[0] === 'activeTimeTab') {
              errorMsg = 'Выберите активную вкладку времени';
            } else if (error.path[0] === 'selectedTransferReason') {
              errorMsg = 'Выберите причину переноса консультации';
            }
          }
          this.$set(this.errors, error.path[0], [errorMsg]);
        });
        return;
      }

      try {
        this.isSaving = true;

        await this.$store.dispatch(this.$types.CONSULTATIONS_TRANSFER, {
          consultationId: this.consultation.value,
          targetDoctorSpecializationId: this.selectedDoctor.mainDoctorSpecialization.id,
          transferReason: this.selectedTransferReason.id,
          unixTime: this.activeTimeTab,
        });

        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_RESET);
        Bus.$emit('consultations:update', this.consultation.value);
        if (this.doctorId) {
          await this.$store.dispatch(this.$types.SCHEDULE_FETCH, {
            id: this.doctorId,
            date: this.activeTab,
          });
        } else {
          this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        }
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    formatConsultation(consultation) {
      if (consultation?.patient) {
        const { lastName, firstName, middleName } = consultation.patient;
        return {
          value: consultation.id,
          name: `${consultation.id} — ${lastName} ${firstName} ${middleName}`,
        };
      }
      const { userFIO } = consultation;
      return {
        value: consultation.id,
        name: `${consultation.id} — ${userFIO}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  width: 100%;
  position: relative;
}
</style>
