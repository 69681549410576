import { z } from 'zod';

const consultationSchema = z.object({
  value: z.number().nonnegative(),
  name: z.string().min(1, 'Имя консультации не может быть пустым'),
});

const specializationSchema = z.object({
  id: z.number().nonnegative(),
  name: z.string().min(1, 'Поле целевой врач не может быть пустым'),
});

const doctorSchema = z.object({
  lastName: z.string().min(1, 'Поле не может быть пустым'),
  middleName: z.string().min(1, 'Поле не может быть пустым'),
  firstName: z.string().min(1, 'Поле не может быть пустым'),
}).passthrough();

const initiatorIdSchema = z.number().nonnegative();

const transferReasonSchema = z.object({
  id: z.number().nonnegative(),
  initiatorType: z.number().nonnegative(),
  reason: z.string().min(1, 'Поле не может быть пустым'),
});
const activeTimeTabSchema = z.number().nonnegative();

const createUnionSchema = (schema, message) => z.union([
  schema,
  z.string().refine((value) => value.trim() !== '', {
    message,
  }),
]);

// eslint-disable-next-line import/prefer-default-export
export const combinedSchema = z.object({
  consultation: consultationSchema,
  selectedSpecialization: createUnionSchema(specializationSchema, 'Выберите специализацию врача'),
  selectedDoctor: createUnionSchema(doctorSchema, 'Выберите врача'),
  selectedInitiatorId: createUnionSchema(initiatorIdSchema, 'Выберите инициатора'),
  selectedTransferReason: createUnionSchema(transferReasonSchema, 'Выберите причину переноса консультации'),
  activeTimeTab: createUnionSchema(activeTimeTabSchema, 'Выберите активную вкладку времени'),
});
